<template>
  <div class="home">
    <!-- 左中右 -->
    <!-- 中 -->
    <div class="home-center">
      <div class="announcement">
        <div class="horNotice">
          <img src="../assets/laba.png" alt="" />
          <div class="horMove">
            <span class="item">
              ：偷偷学习，卷死你们，每天进步一点，
              <!-- 这个插件页面源码在：微信公众号：送外卖劵的程序员，发送xx -->
            </span>
            <span class="item">
              页面源码在微信公众号：送外卖劵的程序员，发送xx获取（待更新）
            </span>
          </div>
        </div>
      </div>

      <p class="record">上次播放:&nbsp;&nbsp;&nbsp;{{ oldName }}</p>
      <h2 class="title">现在视频名称:&nbsp;&nbsp;&nbsp;{{ fileName }}</h2>
      <div class="video-content">
        <player-video :volume="volume" ref="video" :src="src"></player-video>
      </div>
    </div>

    <!-- 右 -->
    <div class="home-right">
      <!-- 左侧方式我们目录和播放记录的地方 -->

      <div class="upload">
        <label class="label">
          <input type="file" @change="uploadImg" ref="inp" />
          <!-- <div class="img-box">
    
        </div> -->

          <p>点击上传,只支持mp4格式</p>

          <div class="upload-icon">
            <!-- <i class="el-icon-plus"></i> -->
            <img class="auto-img" :src="imgUrl" alt />
          </div>
        </label>
        <!-- <p>选择文件或者拖拉文件到这里</p> -->
      </div>

      <div class="btn">
        <el-button type="primary" plain @click="goBack">返回上一页</el-button>
      </div>

      <!-- <button>不符合格式点击跳转转化视频格式</button> -->
    </div>
  </div>
</template>
<script>
import PlayerVideo from '../components/PlayerVideo.vue'

export default {
  components: {
    PlayerVideo,
  },
  data() {
    return {
      volume: 0.1,
      src: 'http://vjs.zencdn.net/v/oceans.mp4',
      imgUrl: require('@/assets/add.png'),
      fileName: '右边选择本地视频', //文件的名字
      oldName: '空',
    }
  },
  created() {
    this.oldName = localStorage.getItem('oldFileName')
    // console.log(files);
    //   this.src = URL.createObjectURL(files)
    // let url = this.getFileURL(files)
  },

  computed: {
    video() {
      return this.$refs.video
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    uploadImg(e) {
      var files = this.$refs.inp.files[0]

      if (files.type != 'video/mp4') {
        // 提示格式不对
        this.$message.error('你提交的视频格式不是mp4')

        return
      }

      // 获取文件的名字 放在头顶上面
      this.fileName = files.name

      // 获取本地存储上次的名字
      let oldName = localStorage.getItem('oldFileName')

      this.oldName = oldName

      //重新存放新的名字
      localStorage.setItem('oldFileName', this.fileName)

      // 本地存放
      let url = this.getFileURL(files)

      console.log(url)
      this.video.toggleTv({
        src: {
          type: 'video/mp4',
          src: url,
        },
        load: url,
      })
    },

    // 转化本地路径
    getFileURL(file) {
      let getUrl = null
      if (window.createObjectURL != undefined) {
        //basic
        getUrl = window.createObjectURL(file)
      } else if (window.URL != undefined) {
        //window.URL 标准定义
        //mozilla(firefox)
        //获取一个http格式的url路径，这个时候就可以设置<img>中的显示
        getUrl = window.URL.createObjectURL(file)
      } else if (window.webkitURL != undefined) {
        //window.webkitURL是webkit的内核
        //webkit or chrome
        getUrl = window.webkitURL.createObjectURL(file)
      }
      return getUrl
    },
  },
  beforeCreate() {
    this.volume = window.localStorage.volume
  },
}
</script>
<style lang="less">
.home {
  min-width: 1200px;
  display: flex;

  .home-center {
    padding: 0 30px;
    min-width: 900px;
    width: calc(100vw - 500px);
    height: 100vh;
    background: #b1bccc30;

    .announcement {
      margin-top: 20px;
      height: 50px;
      background: white;

      .horNotice {
        position: relative;
        width: 100%;
        height: 50px;
        margin: 0 auto;
        overflow: hidden;
        white-space: nowrap;
        line-height: 50px;
        box-shadow: 3px 2px 8px #8f7e7e;
        padding: 0 30px;
        box-sizing: border-box;

        img {
          position: absolute;
          top: 50%;
          left: 5px;
          margin-top: -10px;
          width: 20px;
          height: 20px;
        }
      }
      .horNotice .horMove {
        position: absolute;
        top: 0;
        // left: 100%;
        white-space: nowrap;
        // animation: moveAniHor 20s linear infinite normal;
      }
      .horNotice .horMove .item {
        font-size: 16px;
        text-shadow: 3px 1px 4px #a68241;
      }

      .horNotice .horMove .item:nth-child(2) {
        color: #ff0000;
      }

      @keyframes moveAniHor {
        0% {
          left: 100%;
        }
        50% {
          left: 0;
        }
        100% {
          left: -100%;
        }
      }
    }

    .title {
      // width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 70px;
      text-align: center;
      line-height: 70px;
    }

    .record {
      height: 40px;
      line-height: 40px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .home-right {
    flex-basis: 500px;
    // width: 500px !important;
    height: 100vh;
    background: white;

    .upload {
      margin: 30px auto;
      width: 300px;
      height: 200px;
      border: 1px dashed #d9d9d9;
      transition: border-color 0.2s linear;
      &:hover {
        border-color: #409eff;
      }
      .label {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        [type='file'] {
          display: none;
        }

        .img-box {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 5;
        }
      }

      .auto-img {
        width: 100%;
        display: block;
      }

      p {
        text-align: center;
        height: 100px;
        line-height: 100px;
      }

      .upload-icon {
        position: absolute;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        color: #d9d9d9;
        z-index: 4;
      }
    }

    .btn {
      display: flex;
      justify-content: space-around;
    }
  }
}

// #app {
// font-family: Avenir, Helvetica, Arial, sans-serif;
// -webkit-font-smoothing: antialiased;
// -moz-osx-font-smoothing: grayscale;
// text-align: center;
// color: #2c3e50;
// display: flex;
.video-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;

  .vjs_video_3-dimensions {
    width: 100%;
  }

  .video-js {
    width: 100% !important;
  }
}
.button-group {
  margin-top: 20px;
  display: flex;
  flex: 0 0 100px;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
}
// }
</style>
